/* Header */

#notifications {
    float: right;
    width: 20%;
    display: none;
}

#notifications.admin {
    display: block;
}