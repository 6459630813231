/* Header */

#main-header {
    background-color: #FFFFFF;
    display: grid;
    grid-auto-rows: 130px 7px;
    grid-template-columns: 1fr;
    grid-template-rows: 2;
    height: 130px;
    padding-bottom: 7px;
    margin-bottom: 18px;
}

/* Logo */

#main-header .logo {
    padding-top: 30px;
    justify-self: center;
    width: 200px;
}

/* Gradient */

#main-grad {
    background: radial-gradient(circle 1200px, rgba(41,189,239,1) 0%, rgba(0,114,205,1) 75%, rgba(41,189,239,1) 100%);
    box-shadow: inset 0px 2px 3px 0px rgba(0,0,0,0.25), 0px 4px 4px 0px rgba(0,0,0,0.25);
    width: 100%;
}