html, #App {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #F1F3F4;
}

:active, :focus {
  outline: none;
}
::selection {
  background-color: transparent;
}

a {
  color: #828282;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
}

.derendered {
  display: none !important;
}

h1 {
  cursor: default;
  font: 24px;
  margin: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

h3 {
  color: #FFFFFF;
  cursor: default;
  font-weight: 500;
  margin: 0px;
  margin-block-start: 0px;
  margin-block-end: 3px;
  text-align: center;
}

li {
  list-style-type:none;
}

.no-events {
  pointer-events: none
}

p {
  cursor: default;
}

svg {
  overflow: visible;
  width: 100%;
}

ul {
  margin: 0px;
  padding: 0px;
  text-align: center;
}

/* Gradients */

.grad-1 {
  background: linear-gradient(to right, #0072cd 0%,#29bdef 37%,#29bdef 63%,#0072cd 100%);
  box-shadow: inset 0px 2px 2px 0px rgba(0,0,0,0.25);
  clear: both;
  height: 6px;
  width: 100%;
  border-radius: 0px 0px 5px 5px;
}

/* Alignments */

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

/* Backgrounds */

.simple-bg {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 3px 4px 0px rgba(0,0,0,0.25);
}

.dark-bg {
  background-color: #515151;
  border-radius: 0px 0px 10px 10px;
  box-shadow: inset 0px 4px 4px 0px rgba(0,0,0,0.25);
  padding: 10px 16px;
}

/* Borders */

.border-radius-5-10 {
  border-radius: 5px 5px 10px 10px;
}

.no-border-radius {
  border-radius: 0px;
}

/* Buttons */

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0px;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 12px !important;
  line-height: 1.15;
  margin: 0;
}

button:focus {
  outline: none;
  transition: 0.1s;
}

.button-0 {
  background-color: #FFFFFF;
  border-radius: 5px;
  border: none;
  color: #828282;
  cursor: default;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  max-width: 124px;
  overflow: hidden;
  padding: 6px 14px;
  transition: 0.2s;
  white-space: nowrap;
}

.button-0.empty {
  background-color: #EEEEEE;
  box-shadow: inset 0px 2px 2px 0px rgba(0,0,0,0.25);
}

.button-1 {
  background-color: #0072CD;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.25);
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  padding: 6px 14px;
  transition: 0.2s;
}

.button-1:hover {
  background-color: #0B87EA;
}

.button-1:active {
  background-color: #29BDEF;
  box-shadow: none;
  transform: translateY(1px);
}

.button-2 {
  background-color: #29BDEF;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.25);
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  padding: 6px 14px;
  transition: 0.2s;
}

.button-2:hover {
  background-color: #4DCAF5;
}

.button-2:active {
  background-color: #3BCDFF;
  box-shadow: none;
  transform: translateY(1px);
}

.button-1.deactivated, .button-2.deactivated, .button-1.deactivated:hover, .button-2.deactivated:hover {
  background-color: #777777;
  box-shadow: inset 0px 1px 2px 0px rgba(0,0,0,0.25);
  color: #EFEFEF;
  cursor: default;
  transform: translateY(1px);
}

.button-2.deactivated, .button-2.deactivated:hover {
  background-color: #A2A2A2;
}

.button-3 {
  background-color: #BE0808;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.25);
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  padding: 6px;
  transition: 0.2s;
}

.button-3:hover {
  background-color: #D80808;
}

.button-3:active {
  background-color: #E84049;
  box-shadow: none;
  transform: translateY(1px);
}

.button-4 {
  background-color: #A2A2A2;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.25);
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  padding: 6px;
  transition: 0.2s;
}

.button-4:hover {
  background-color: #B9B9B9;
}

.button-4:active {
  background-color: #CCCCCC;
  box-shadow: none;
  transform: translateY(1px);
}

.button-5 {
  background-color: #EEEEEE;
  border-radius: 5px;
  border: solid 2px #A2A2A2;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.25);
  color: #A2A2A2;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  padding: 6px;
  transition: 0.2s;
}

.button-5:hover {
  background-color: #FFFFFF;
}

.button-5:active {
  background-color: #FFFFFF;
  box-shadow: none;
  transform: translateY(1px);
}

.button-5.active {
  background-color: #FFFFFFFF;
  border-radius: 5px;
  border: solid 2px #0072CD;
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.25);
  color: #0072CD;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  padding: 6px;
  transition: 0.2s;
}

/* Content */

.content-card {
  background-color: #FFFFFF;
  border-radius: 5px;
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.25);
  cursor: pointer;
  transition: 0.2s;
}

.content-card:hover{
  background-color: #FFFFFF;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.25);
  transform: translateY(2px);
}

/* Corrections */

.no-border-radius {
  border-radius: 0px;
}

/* Layout */

.cols2-1 {
  grid-column: 1 / 3;
}

.margin-0 {
  margin: 0px;
}

.margin-l6 {
  margin-left: 6px;
}

.width-100 {
  width: 100%;
}


/* Inputs */

input::selection {
  color: white;
  background-color: #29BDEF88;
}

input, textarea, select {
  border: none;
  color: #828282;
  line-height: 1;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 200;
  margin: 0px;
  padding: 0px;
  width: 100%;
}

input::placeholder, textarea {
  border: none;
  color: #BBBBBB;
  font-family: 'Roboto', sans-serif;
  margin: 0px;
  padding: 0px;
  width: 100%;
}

input:focus, textarea {
  outline: none;
}

textarea#intro {
  border-radius: 5px;
  overflow: hidden;
  resize: vertical;
  padding: 5px 8px;
  width: calc(100% - 16px);
}

select:focus, select:active {
  outline: 0;
}
option {
  outline: 0;
  border: none;
}

option:hover {
  background-color: turquoise;
}

.nutrient-title input {
  background-color: #CCCCCC;
  color: white;
}

.input-container-1 {
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 60px auto;
  margin: 0px auto;
  width: 100%;
}

.input-container-1.label100 {
  grid-template-columns: 100px auto;
}

.input-container-1 .double-input {
  display: grid;
  grid-template-columns: calc(50% - 1px) 1px auto;
}

.input-container-1.nutrient {
  grid-template-columns: auto 60px;
  z-index: 1000;
}

.center-border {
  background-color: #CCCCCC;
}

.input-container-1 input {
  border-radius: 5px;
  padding: 5px 8px;
  width: calc(100% - 16px);
}

.input-label-1.nutrient-title {
  border-radius: 5px 0px 0px 5px;
  height: auto;
}

.input-container-1.invalid {
  border: 1px solid #E20303;
}

.input-container-1.invalid .input-label-1 {
  background-color: #E20303;
  border: 1px solid #E20303;
}

.input-container-1.invalid .center-border {
  background-color: #E20303;
}
.input-container-1.invalid .nutrient-title input {
  background-color: #E20303;
}

.input-label-1 {
  align-items: center;
  color: white;
  background-color: #0072CD; 
  border: 1px solid #005FAA;
  border-radius: 5px 0px 0px 5px;
  display: grid;
  height: 100%;
  font-size: 12px;
  margin: -1px 0px -1px -1px;
  text-align: center;
}

.input-label-1.grey-label {
  background-color: #CCCCCC;
  border: 1px solid #CCCCCC;
}

.textarea-1 {
  border: 1px solid #005FAA;
  color: #333333;
  line-height: 1.3;
  height: 200px;
  margin-top: 20px;
  padding: 10px;
  resize: none;
  width: calc(100% - 20px);
}

/* Overflows */

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

/* Overlays */

.popup-overlay {
  background-color: #00000000;
  height: 100vh;
  position: fixed;
  top: 0px;
  transition: 0.2s;
  width: 100%;
}

.popup-overlay.active {
  background-color: #00000055;
}

/* Padding */

.padding30 {
  padding: 30px;
}

.padding16 {
  padding: 16px;
}

.no-padding {
  padding: 0px;
}

/* Searchbars */

#searchbar1 {
  display: grid;
  grid-column-gap: 60px;
  grid-template-columns: auto auto;
  width: calc( 100% - 32px );
}

.search-container {
  background-color: #FFFFFF;
  border: 1px solid #9C9C9C;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 15px auto;
  padding: 2px 8px;
}

.search-icon{
  padding: 0px 4px 0px 0px;
}

.searchbox {
  display: inline-block;
  transition: ease-out 0.5s;
}

/* Texts */

p {
  color: #828282;
  font-size: 14px;
  margin: 9px 0px;
}

h1 {
  color: #333333;
  font-size: 24px;
  font-weight: 500;
  margin: 0px;
  text-align: center;
}