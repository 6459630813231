/* Profile Picture */

#profile-picture-border {
    border-radius: 50%;
    height: 90px;
    margin: auto;
    width: 90px;
}

#profile-picture {
    background-color: #F1F3F4;
    background-image: url("../../../../img/profile-picture-placeholder.jpg");
    background-size: contain;
    border-radius: 50%;
    height: 90px;
    margin: auto;
    width: 90px;
}

#profile-picture-border.admin {
    background: linear-gradient(45deg, rgb(255, 163, 0) 15%, rgb(41, 189, 239) 45%, rgb(0, 114, 205) 90%);
    padding: 4px;
}
#profile-picture-border.webmaster {
    background: linear-gradient(45deg, rgb(255, 163, 0) 15%, rgb(171, 171, 171) 40%, rgb(0, 0, 0) 95%);
    padding: 4px;
}

#profile-picture-border.base {
    border: 1px solid #CCC;
    padding: 3px;
}

#profile-picture-border.founder-user {
    background: linear-gradient(30deg, rgb(41, 189, 239) 30%, rgb(0, 114, 205) 90%);
    padding: 4px;
}

/* User Information */

#user-info {
    margin: 16px 0px 20px;
    text-align: center;
}

#user-info #user-name {
    margin: 0px;
    font-size: 17px;
    color: #333333;
}
#user-info #user-title {
    margin: 4px 0px 0px;
}

