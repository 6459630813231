/* Component Background */

#preland-div {
    width: 370px;
    margin: 50px auto;
}

#preland-div .bg-padding{
    padding: 50px 40px 0px;
}

/* Logo */

#preland-div .logo {
    margin:auto;
    width: 200px;
}

/* Form */

#login-form {
    padding: 22px 0px 22px 0px;
    width: 100%;
}

#login-form input {
    border: 1px solid #C7C7C7;
    border-radius: 5px;
    color: #606060;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    height: 14px;
    margin-bottom: 16px;
    padding: 6px 10px 6px 10px; 
    width: calc(100% - 22px);
}

#login-form input::placeholder {
    color: #C7C7C7;
    font-family: 'Roboto', sans-serif;
}