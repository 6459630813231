/* Layout */

#main-content {
    float: left;
    width: calc(80% - 20px);
    margin: 0px 0px 0px 20px;
    z-index: 0;
}

#main-content.admin {
    float: left;
    width: calc(60% - 40px);
    margin: 0px 20px;
}

/* Allergen Selector */

#allergen-selector {
    display: grid;
    grid-column-gap: 8px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    vertical-align: center;
}

button.allergen {
    border-radius: 50%;
    filter: brightness(0.6);
    max-height: 20px;
    transition: 0.2s;
}

button.allergen:hover {
    filter: brightness(0.9);
}

button.allergen.active {
    box-shadow: 0px 0px 2px 0px rgba(255,255,255,1);
    filter: brightness(1);
}

/* Infographics */

#add-infographic-category {
    border: 1px dashed #888888;
    border-radius: 100%;
    cursor: default;
    height: 70px;
    text-align: center;
    transition: 0.2s;
    width: 70px;
}

#add-infographic-category-wrapper .infographic-category-photo {
    background-color: #FFFFFF00;
}

#add-infographic-category-wrapper.active .infographic-category-photo {
    background-color: #FFFFFF22;
}

#add-infographic-category-wrapper.allowed #add-infographic-category {
    border: 1px dashed #FFFFFF;
    cursor: pointer;
}

#add-infographic-category-wrapper input {
    color: #888888;
    transition: 0.2s;
    pointer-events: none;
}

#add-infographic-category-wrapper.allowed input {
    color: #FFFFFF;
}

#add-infographic-category .add-plus-sign-20 {
    color: #888888;
    font-size: 20px;
    margin: 0px;
    pointer-events: none;
    transition: 0.2s;
}

#add-infographic-category-wrapper.allowed .add-plus-sign-20 {
    color: #FFFFFF;
}

#add-infographic-wrapper {
    height: 0px;
    margin: 18px auto 0px;
    overflow: hidden;
    transition: 0.2s;
    width:220px;
}

#add-infographic-wrapper.active {
    height: 238px;
}

#add-infographic-wrapper.active.open {
    width: calc(50px + 220px + 260px);
}

#image-selector {
    display: grid;
    float:left;
    height: 200px;
    transition: 0.2s;
    width: 0px;
}

#image-selector.active {
    width: 50px;
}

#image-selector-wrapper {
    margin-top: 20px;
    overflow: hidden;
    overflow-y: scroll;
    padding: 1px;
}
#image-selector-wrapper li {
    height: 49px;
    padding-bottom: 2px;
}

#current-image-wrapper {
    float: left;
    overflow: hidden;
    width: 220px;
}

#current-image {
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 100%;
    width: 100%;
}

#infographics-button-row {
    align-items: center;
    display: grid;
    grid-column-gap: 6px;
    grid-template-columns: auto 125px 80px;    
}

#infographic-form {
    border-radius: 0px 5px 5px 0px;
    box-shadow: 0px 3px 4px 0px rgba(0,0,0,0.25);
    float: left;
    height: 160px;
    margin-top: 10px;
    overflow: hidden;
    padding: 20px 0px;
    transition: 0.2s;
    width: 0px;
}

#infographic-form div {
    margin-bottom: 10px;
}

#infographic-form.active {
    height: 160px;
    padding: 20px;
    width: 220px;
}

#new-infographic-button-row-1 {
    column-gap: 6px;
    display: grid;
    grid-template-columns: 90px auto; 
}

#new-infographic-button-row-2 {
    column-gap: 6px;
    display: grid;
    grid-template-columns: 28px 80px auto; 
}

.infographic-category{
    float: left;
    text-align: center;
    padding: 10px 0px 10px;
    transition: 0.2s;
    width: 100px;
}

.infographic-category:hover {
    transform: scale(1.05);
}

.infographic-category.allowed{
    float: left;
    text-align: center;
    padding: 10px 0px 10px;
    width: 100px;
}

.infographic-category p {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    margin: 8px 0px 0px;
}

.infographic-category .infographic-category-photo-wrapper {
    border: 1px solid #FFFFFF;
    border-radius: 100%;
    cursor: pointer;
    height: 70px;
    margin-bottom: 5px;
    overflow: hidden;
    padding: 2px;
    position: relative;
    text-align: center;
    transition: 0.2s;
    width: 70px;
}

.infographic-category .infographic-category-photo {
    background-color: #FFFFFF22;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 100%;
    height: 62px;
    left: 3px;
    position: absolute;
    top: 3px;
    transition: 0.2s;
    width: 62px;
}

.infographic-category-photo-wrapper.editing .infographic-category-photo {
    background-color: #00000044;
    background-blend-mode: darken;
}

.infographic-category-photo-wrapper .edit-icon-wrapper {
    width: 65%;   
    margin: 4px auto 0px; 
    position: relative;
}
  
.infographic-category-photo-wrapper .edit-icon-wrapper svg {
    fill: transparent;
    transition: 0.2s;
}

.infographic-category-photo-wrapper.editing .edit-icon-wrapper svg {
    fill: #FFFFFF;
    z-index: 10000;
}

.infographic-category-photo-upload {
    display: none;
}


#infographic-categories-list {
    margin: 0px;
    overflow-x: auto;
    position: relative;
    width: 100%;
}

#image-selector-wrapper::-webkit-scrollbar {
    height: 0px;
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

#infographic-categories-list::-webkit-scrollbar {
    height: 0px;
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}

#infograpic-categories-ul {
    display: grid;
    grid-template-columns: repeat( 1000, 1fr );
    column-gap: 6px;
}

#infograpic-categories-ul li {
    grid-template-columns: 100px;
}

.new-infographic-photo-upload {
    display: none;
}


.infographic-category-name {
    background-color: transparent;
    border: 1px solid #ffffff00;
    border-radius: 6px;
    box-sizing: border-box;
    color: white;
    text-align: center;
    transition: 0.2s;
}

.infographic-category-name:hover {
    border: 1px solid #ffffffcc;
}

.infographic-category-name:focus {
    background-color: #FFFFFF11;
    border: 1px solid #ffffffcc;
}

#add-infographic-category-wrapper.allowed:hover .infographic-category-photo {
    background-color: #FFFFFF70;
}

#new-infographic-button {
    float: right;
}

.infographic-category.active { 
    transform: scale( 1.1 );
}

.infographic-category.active .infographic-category-photo-wrapper { 
    border: 2px solid #FFFFFF;
    transform: scale( 1.1 );
}

.infographic-category.active .infographic-category-photo { 
    height: 60px;
    width: 60px;
}

#add-infographic-images {
    border: 1px dashed #888888;
    border-radius: 5px;
    cursor: pointer;
    height: 49px;
    position: relative;
    text-align: center;
    transition: 0.2s;
    width: 100%;
}

#add-infographic-images.active {
    border: 1px dashed #FFFFFF;
}

#add-infographic-images .add-infographic-photo {
    background-color: #FFFFFF00;
    border-radius: 5px;
    width: calc( 100% - 6px );
    height: calc( 100% - 6px );
    margin: 3px;
    position: absolute;
    top: 0px;
    transition: 0.2s
}

#add-infographic-images.active .add-infographic-photo {
    background-color: #FFFFFF22;
}

#add-infographic-images.active:hover .add-infographic-photo {
    background-color: #FFFFFF70;
}

#add-infographic-images .add-plus-sign-20 {
    color: #888888;
    font-size: 17px;
    padding: 2px;
    pointer-events: none;
    transition: 0.2s;
}

#add-infographic-images.active .add-plus-sign-20 {
    color: #FFFFFF;
}

.infographic-image-card {
    background-blend-mode: darken;
    background-color: #00000033;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50px;
    text-align: center;
    transition: 0.2s;
    transform: scale(0.9);
    width: 100%;
}

.infographic-image-card:hover {
    background-color: #00000011;
    transform: scale(0.98);
}

.infographic-image-card.active {
    background-color: #00000000;
    transform: scale(1);
}

.infographic-card {
    background-position: center center;
    background-size: calc( 100% + 4px ) calc( 100% + 4px );
    cursor: pointer;
    border: 2px solid transparent;
    box-sizing: border-box;
    padding-bottom: 100%;
    transition: 0.2s;
    width: 100%;
}

.infographic-card:hover {
    transform: scale( 1.02 );
}

.infographic-card.unpublished {
    background-color: #00000033;
    background-blend-mode: darken;
}
.infographic-card.active {
    border: 2px solid #29BDEF;
    transform: scale( 1.02 );
}
#infographics-ul {
    column-gap: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 16px;
    transition: 0.5s;
    width: 100%;
}
#infographic-popup {
    max-width: 800px;
    position: absolute;
    top: calc( 50% - 200px);
    left: calc( 50% - 200px);
    transition: 0.3s;
    width: 90%;
}
#infographic-popup-image {
    background-size: cover;
    height: 0px;
    float: left;
    transition: 0.2s;
    width: 400px;
    z-index: 1000;
}
#infographic-popup-image.active {
    padding-bottom: 400px;
    width: 400px;
}
#popup-anim {
    transition: 0.3s;
    width: 400px;
}
#popup-anim.active {
    width: 100%;
}
#infographic-popup.active {
    left: calc( 50% - 500px);
}
#popup-content {
    border-radius: 0px 10px 10px 0px !important;
    height: 370px;
    margin-top: 15px;
    transition: 0.3s;
    width: 0px;
    float: left;
    overflow: hidden;
}
#infographic-popup-image-selector {
    height: 340px;
    margin-top: 30px;
    transition: 0.3s;
    width: 0px;
    float: left;
}
#popup-content.active {
    width: 340px;
}
#popup-content.active div {
    padding: 40px;
}
#infographic-popup-image-selector.active {
    width: 60px;
}

/* Ingredients */

#ingredient-form {
    display: grid;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    grid-template-columns: 20% 20% 30% 30%;
    grid-template-areas: 
        "name name recipe recipe"
        "serving protein carbohydrates fat";
    width: calc(100% - 36px);
}

#ingredient-form .name {
    grid-area: name;
}

#ingredient-form .recipe {
    grid-area: recipe;
}

#ingredient-form .serving {
    grid-area: serving;
}

#ingredient-form .protein {
    grid-area: protein;
}

#ingredient-form .carbohydrates {
    grid-area: carbohydrates;
}

#ingredient-form .fat {
    grid-area: fat;
}

#ingredient-list {
    display: grid;
    grid-column-gap: 16px;
    grid-row-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 15px 0px 20px;
}

#ingredient-list .content-card {
    font-size: 12px;
    padding: 6px 36px 6px 36px;
    text-align: center;
}

#ingredient-list .content-card:hover {
    background-color: #DDDDDD;
}

#ingredient-list .content-card.active {
    border: 2px solid #29BDEF;
    padding: 4px 6px; 
}

#ingredient-list .content-card.active:hover {
    background-color: #FFFFFF;
    box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.25);
    transform: none;
}

#ingredient-button-row {
    align-items: center;
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: auto auto auto;
    z-index: 0;
}

#button-row .search-container {
    width: calc(100% - 18px);
}

#trashcan-div {
    line-height: 0px;
    margin-bottom: 0px !important;
    position: relative;
    text-align: center;
    width: 100%;
}

#ingredient-buttons {
    align-items: center;
    display: grid;
    grid-column-gap: 6px;
    grid-template-columns: 28px 80px;
}

#delete-button {
    height: 25.33px;
    padding: 4px 10px 6px;
}

#trashcan-lid {
    transform: translateY(-1px);
    transition: 0.2s;
}

#trashcan-body {
    align-self: center;
    transition: 0.2s;
    width: 85%;
}

#delete-button:hover #trashcan-lid {
    transform: translateY(-1.5px);
}

#delete-button:hover #trashcan-body {
    transform: translateY(0.5px);
}

/* Nutrients */

#nutrients {
    display: grid;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    grid-template-columns: 25% 25% 25% 25%;
    margin: 12px 0px;
    width: calc(100% - 36px);
}

#add-nutrient {
    background-color: #FFFFFF00;
    border: 1px dashed #888888;
    border-radius: 5px;
    cursor: default;
    text-align: center;
    transition: 0.2s;
    width: 100%;
    pointer-events: none;
    transition: none;
}

#add-nutrient p {
    color: #888888;
    font-size: 12px;
    margin: 0px;
    padding: 6px 5px 5px;
}

.nutrient-card {
    display: grid;
    grid-template-columns: auto 60px;
    margin: 0px;
    position: relative;
}

#add-nutrient.active {
    background-color: #FFFFFF22;
    border: 1px dashed #FFFFFF;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: 0.2s;
    width: 100%;
    pointer-events: auto;
}

#add-nutrient.active:hover {
    background-color: #FFFFFF70;
}

#add-nutrient.active p {
    color: #FFFFFF;
    font-size: 12px;
    margin: 0px;
}

/* Recipes */

#recipe-list {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 15px 0px 20px;
}

.recipe-img {
    background-color: #C4C4C4;
    background-image: url('../../../../img/recipe-img-placeholder.jpg');
    background-position: center center;
    background-size: cover;
    border-radius: 5px 5px 0px 0px;
    height: 110px;
}

.recipe-name {
    padding: 10px;
    text-align: center;
}

.recipe-name p{
    margin: 0px;
}

.new-recipe-card-wrapper {
    margin: 16px auto 20px;
    max-width: 224px;
}

.new-recipe-card-wrapper .recipe-img:hover {
    filter: brightness(0.8);
}

#new-recipe-form {
    margin: auto;
    max-width: 500px;
}