/* Sidebar */

#sidebar {
    float: left;
    margin: 0px;
    width: 20%;
}

/* List Items */

.sidebar-nav li {
    padding: 7px 0px 7px;
}

.sidebar-nav a:hover {
    font-weight: 600; 
}
.sidebar-nav a.active {
    color: #333333;
    font-weight: 600; 
}


/* Spacer */

.spacer1 {
    background: radial-gradient(circle, rgba(130,130,130,1) 0%, rgba(255,255,255,1) 100%);
    height: 1px;
    margin: 10px auto 10px;
    width: 100%;
}